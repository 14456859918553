import { bucketConstants } from "../constants/ActionTypes";

const initialState = {
  buckets: [],
  calledDisposition: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case bucketConstants.GET_BUCKET_ALL:
      return { ...state, buckets: payload };

    case bucketConstants.GET_CALLED_DISPOSITION_ALL:
      return {
        ...state,
        calledDisposition: payload,
      };
    case bucketConstants.GET_BUCKET_SEARCH:
      return {
        ...state,
        buckets: payload,
      };

    default:
      return state;
  }
};
