import { useEffect, useState } from "react";
import "./incentive.css";

export const IncentiveTable = ({
  roleType,
  incentiveType,
  updateIncentiveData,
  roleData,
}) => {
  const [erros, setErrors] = useState("");
  const [showAddField, setShowAddField] = useState(false);
  const [mode, setMode] = useState("");
  const [incentives, setIncentives] = useState([]);
  const [newIncentive, setNewIncentive] = useState({
    min: "",
    max: "",
    incentive: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewIncentive((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleIncentiveAdd = (e) => {
    e.preventDefault();
    const { min, max, incentive } = newIncentive;

    if (!min || !max || !incentive) {
      return setErrors("All fields are required.");
    }

    const minVal = Number(min);
    const maxVal = Number(max);
    const incentiveVal = Number(incentive);

    if (minVal < 0 || maxVal < 0 || incentiveVal < 0) {
      return setErrors("Min, max, and incentive values must not be negative.");
    }

    if (maxVal <= minVal) {
      return setErrors("Max must be greater than Min.");
    }

    setErrors("");
    if (min && max && incentive) {
      setIncentives((prevState) => [
        ...prevState,
        { min, max, amount: incentive },
      ]);
      setShowAddField(false);
      setNewIncentive({ min: "", max: "", incentive: "" });
    }
  };

  useEffect(() => {
    const type =
      incentiveType === "Target"
        ? incentiveType
        : incentiveType === "Efficieny"
        ? "Efficieny %"
        : "";
    setIncentives(roleData);
    setMode(type);
  }, [incentiveType]);

  useEffect(() => {
    if (incentives) {
      const data = {
        roleType,
        incentives,
      };
      updateIncentiveData(data);
    }
  }, [incentives]);

  useEffect(() => {
    if (roleData?.length !== 0) {
      setIncentives(roleData);
    }
  }, [roleData]);

  return (
    <>
      <h3 class="incentive-title">{roleType}</h3>
      <div class="table-incentive" key={`${roleType}-table`}>
        <table class="table table-content table-striped">
          <thead>
            <tr>
              <th>{mode}</th>
              <th>Incentive</th>
            </tr>
          </thead>
          <tbody>
            {incentives.map((item) => {
              return (
                <tr class="selected">
                  <td>
                    {item?.min} - {item?.max}
                  </td>
                  <td>{item?.amount}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <a href="#" class="gray-circle" onClick={() => setShowAddField(true)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <path
              d="M11.5 17H13.5V13H17.5V11H13.5V7H11.5V11H7.5V13H11.5V17ZM12.5 22C11.1167 22 9.81667 21.7373 8.6 21.212C7.38333 20.6867 6.325 19.9743 5.425 19.075C4.525 18.175 3.81267 17.1167 3.288 15.9C2.76333 14.6833 2.50067 13.3833 2.5 12C2.5 10.6167 2.76267 9.31667 3.288 8.1C3.81333 6.88333 4.52567 5.825 5.425 4.925C6.325 4.025 7.38333 3.31267 8.6 2.788C9.81667 2.26333 11.1167 2.00067 12.5 2C13.8833 2 15.1833 2.26267 16.4 2.788C17.6167 3.31333 18.675 4.02567 19.575 4.925C20.475 5.825 21.1877 6.88333 21.713 8.1C22.2383 9.31667 22.5007 10.6167 22.5 12C22.5 13.3833 22.2373 14.6833 21.712 15.9C21.1867 17.1167 20.4743 18.175 19.575 19.075C18.675 19.975 17.6167 20.6877 16.4 21.213C15.1833 21.7383 13.8833 22.0007 12.5 22ZM12.5 20C14.7333 20 16.625 19.225 18.175 17.675C19.725 16.125 20.5 14.2333 20.5 12C20.5 9.76667 19.725 7.875 18.175 6.325C16.625 4.775 14.7333 4 12.5 4C10.2667 4 8.375 4.775 6.825 6.325C5.275 7.875 4.5 9.76667 4.5 12C4.5 14.2333 5.275 16.125 6.825 17.675C8.375 19.225 10.2667 20 12.5 20Z"
              fill="#00549E"
            />
          </svg>
        </a>
        {showAddField ? (
          <>
            <div className="d-flex addIncentiveBox justify-content-between">
              <form
                className="d-flex justify-content-between form-incentive-input"
                name="Tele Caller"
                onSubmit={handleIncentiveAdd}
              >
                <input
                  name="min"
                  type="number"
                  className="col-md-3"
                  placeholder="min"
                  value={newIncentive.min}
                  onChange={handleInputChange}
                />
                <input
                  type="number"
                  name="max"
                  className="col-md-3"
                  placeholder="max"
                  value={newIncentive.max}
                  onChange={handleInputChange}
                />
                <input
                  name="incentive"
                  type="number"
                  className="col-md-3"
                  placeholder="amount"
                  value={newIncentive.incentive}
                  onChange={handleInputChange}
                />
                <button type="submit" className="col-md-2 form-control">
                  Add
                </button>
              </form>
            </div>
            {erros && <small className="text-danger">{erros}</small>}
          </>
        ) : null}
      </div>
    </>
  );
};
