export const Role = {
  ADMIN: "ADMIN",
  PARTNER: "PARTNER",
  COLLECTOR: "COLLECTOR",
  SUPER_ADMIN: "SUPER_ADMIN",
  CUSTOMER: "CUSTOMER",
  MANAGER: "MANAGER",
  CLIENT_ADMIN: "CLIENT_ADMIN",
  CLIENT: "CLIENT",
  TENANT_ADMIN: "CRM_TenantAdmin",
  CRM_TENANTADMIN: "CRM_TenantAdmin",
  CRM_MANAGER: 'CRM_Manager',
  CRM_TELECALLER: "CRM_TeleCaller",
  CRM_FIELDAGENT: "CRM_FieldAgent",
  CRM_SUPERMANAGER: "CRM_SuperManager",
  CRM_SUPERADMIN: "CRM_SuperAdmin",
};
