import {
  RegistrationAction,
  RegistrationConstant,
} from "../constants/ActionTypes";

const initState = {
  registrationDetail: [],
  edit: {
    isEdit: false,
    id: null,
  },
};

export default (state = initState, action) => {
  switch (action.type) {
    case RegistrationConstant.GET_REGISTRATION_DETAIL:
      return { ...state, registrationDetail: action.payload };

    case RegistrationAction.TOGGLE_EDIT:
      return { ...state, edit: action.payload };
    default:
      return state;
  }
};
