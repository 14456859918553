import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";

import "../ClientStyles.scss";

import history from "../../../history";
import PortfolioApi from "../../common/Network/portfolioApi";
import { getLoginSub } from "../../Login/authUtils";
import { IncentiveTable } from "./incentiveStructureConstant";
import { alertActions } from "../../../actions";

const sub = getLoginSub();

export class NewIncentiveStructure extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      portfolioDetail: this.props.location?.state || {},

      selectedRoles: {
        "Tele Caller": false,
        "Field Agent": false,
      },
      errors: {},
      selectedModes: ["Target", "Efficieny"],
      incentiveData: {
        incentiveType: "",
        assignedTeleCaller: 0,
        assignedFieldAgent: 0,
      },
    };
  }

  onChangeHandle = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      incentiveData: {
        ...prevState.incentiveData,
        [name]: value,
      },
    }));
  };

  componentDidMount() {
    if (this.state.portfolioDetail?.incentives) {
      const incentiveData = this.state.portfolioDetail?.incentives;
      this.setState({
        incentiveData: {
          ...incentiveData,
          assignedTeleCaller: this.state.portfolioDetail.collectors.length,
          assignedFieldAgent: this.state.portfolioDetail.fieldAgents.length,
        },
        selectedRoles: {
          "Tele Caller": incentiveData.taIncentives ? true : false,
          "Field Agent": incentiveData.faIncentives ? true : false,
        },
      });
    }
  }

  updateIncentive = async () => {
    const updatedIncentive = await PortfolioApi.updateIncentive(
      this.state.portfolioDetail.id,
      sub,
      this.state.incentiveData
    );
    if (updatedIncentive.status === 200) {
      alertActions.success("Incentive update sucessfully");
      history.push("/view-client");
    }
  };

  onSubmitHandle = (e) => {
    e.preventDefault();

    this.updateIncentive();
  };

  toggleRoleSelection = (role) => {
    this.setState((prevState) => ({
      selectedRoles: {
        ...prevState.selectedRoles,
        [role]: !prevState.selectedRoles[role],
      },
    }));
  };

  handleModeChange = (e) => {
    const { value, name, checked } = e.target;
    this.setState((prevState) => ({
      incentiveData: {
        ...prevState.incentiveData,
        [name]: value,
      },
    }));
  };

  updatedIncentiveData = (data) => {
    const type =
      data.roleType === "Tele Caller"
        ? "taIncentives"
        : data.roleType === "Field Agent"
        ? "faIncentives"
        : "";
    if (type) {
      this.setState((prevState) => ({
        incentiveData: {
          ...prevState.incentiveData,
          [type]: data.incentives,
        },
      }));
    }
  };

  getRoleData = (roleType) => {
    let roleData = [];
    const incentiveData = this.state.portfolioDetail?.incentives;
    if (!incentiveData) roleData = [];
    const {
      incentiveData: { incentiveType },
    } = this.state;
    const Updatedtype = incentiveData?.incentiveType;
    if (incentiveType === Updatedtype) roleData = incentiveData[roleType] || [];

    return roleData;
  };

  render() {
    const { assignedTeleCaller, assignedFieldAgent } = this.state.incentiveData;
    const { selectedModes } = this.state;

    return (
      <>
        <div class="main-content">
          <div class="page-title-row">
            <h1 class="setAgentProfile">Create Portfolio</h1>
            <ol class=" breadcrumb">
              <li class="changeContent breadcrumb-item">
                <a class="setColor" onClick={() => history.push("/dev/")}>
                  Home
                </a>
              </li>
              <li class="breadcrumb-item active setAgentProfile">Incentive</li>
            </ol>
          </div>

          <div class="content-box">
            <div class="tab-panel-card">
              <ul class="tab-list-1">
                <li>
                  <a>Client Process & Information</a>
                </li>
                <li>
                  <a>Settlement & Foreclosure Policies</a>
                </li>
                <li>
                  <a>Target Setting</a>
                </li>

                <li class="active">
                  <a>Incentive Structure</a>
                </li>
              </ul>
            </div>

            <div className="white-card">
              <div className="white-card-body">
                <div className="box-style-2 ">
                  <div className="flex-row-1">
                    <fieldset>
                      <label className="legend">Set Incentive for :</label>
                      {Object.keys(this.state.selectedRoles).map(
                        (role, index) => (
                          <div className="custom-checkbox" key={role + index}>
                            <input
                              className="styled-checkbox"
                              id={`'checkbox'-${role}`}
                              type="checkbox"
                              name="roleType"
                              value="Tele Caller"
                              checked={this.state.selectedRoles[role]}
                              onChange={() => this.toggleRoleSelection(role)}
                            />
                            <label htmlFor={`'checkbox'-${role}`}>{role}</label>
                          </div>
                        )
                      )}
                    </fieldset>

                    <fieldset>
                      <label className="legend">Incentive Mode:</label>
                      {selectedModes.map((mode) => (
                        <div className="custom-radio" key={mode}>
                          <input
                            type="radio"
                            id={`settlement-${mode}`}
                            name="incentiveType"
                            value={mode}
                            checked={
                              this.state.incentiveData.incentiveType === mode
                            }
                            className="custom-control-input"
                            onChange={this.handleModeChange}
                          />
                          <label
                            className="custom-control-label1"
                            htmlFor={`settlement-${mode}`}
                          >
                            {mode}
                          </label>
                        </div>
                      ))}
                    </fieldset>
                  </div>
                </div>
              </div>

              <div className="white-card-body">
                <div className="box-style-2 mt-4 mb-4">
                  <div className="row">
                    <div className="divider-line"></div>

                    {this.state.selectedRoles["Tele Caller"] &&
                      this.state.incentiveData.incentiveType && (
                        <div className="col-md-4">
                          <IncentiveTable
                            roleType="Tele Caller"
                            roleData={this.getRoleData("taIncentives")}
                            incentiveType={
                              this.state.incentiveData.incentiveType
                            }
                            updateIncentiveData={this.updatedIncentiveData}
                          />
                        </div>
                      )}

                    {this.state.selectedRoles["Field Agent"] &&
                      this.state.incentiveData.incentiveType && (
                        <div className="col-md-4">
                          <IncentiveTable
                            roleType="Field Agent"
                            roleData={this.getRoleData("faIncentives")}
                            incentiveType={
                              this.state.incentiveData.incentiveType
                            }
                            updateIncentiveData={this.updatedIncentiveData}
                          />
                        </div>
                      )}

                    <div className="col-md-3">
                      <div className="center-1">
                        <div className="mb-4">
                          <label className="form-label">
                            Assigned Tele Caller{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control width120"
                            value={assignedTeleCaller}
                            name="assignedTeleCaller"
                            onChange={this.onChangeHandle}
                            disabled
                          />
                        </div>
                        <div className=" ">
                          <label className="form-label">
                            Assigned Field Agent
                          </label>
                          <input
                            type="text"
                            className="form-control width120"
                            value={assignedFieldAgent}
                            name="assignedFieldAgent"
                            onChange={this.onChangeHandle}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="divider-line"></div>

                <div className="white-card-footer">
                  <button
                    onClick={this.onSubmitHandle}
                    type="button"
                    className="btn"
                  >
                    SAVE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default NewIncentiveStructure;
