import { callManagementConstants } from "../constants/ActionTypes"
const dispCodes = [
    {
        _id: "5f16b059e2ba2d1cc0eb7f31",
        name: "NOT_CONNECTED",
        createdAt: "2020-07-21T09:07:37.265Z",
        updatedAt: "2020-07-21T09:07:37.265Z",
        __v: 0,
        shortCode: "NC",
        codedispositions: [
            {
                _id: "5f16c363f775003920a5a919",
                name: "Not Reachable / Swtiched off",
                dispostionId: "5f16b059e2ba2d1cc0eb7f31",
                isDate: false,
                isTime: false,
                isAmount: false,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: false,
                createdAt: "2020-07-21T10:28:51.638Z",
                updatedAt: "2020-07-21T10:28:51.638Z",
                __v: 0
            },
            {
                _id: "5f16c383f775003920a5a91a",
                name: "Number incorrect",
                dispostionId: "5f16b059e2ba2d1cc0eb7f31",
                isDate: false,
                isTime: false,
                isAmount: false,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: false,
                createdAt: "2020-07-21T10:29:23.953Z",
                updatedAt: "2020-07-21T10:29:23.953Z",
                __v: 0
            },
            {
                _id: "5f16c392f775003920a5a91b",
                name: "Incoming barred",
                dispostionId: "5f16b059e2ba2d1cc0eb7f31",
                isDate: false,
                isTime: false,
                isAmount: false,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: false,
                createdAt: "2020-07-21T10:29:38.887Z",
                updatedAt: "2020-07-21T10:29:38.887Z",
                __v: 0
            },
            {
                _id: "5f16c3a9f775003920a5a91c",
                name: "Busy",
                dispostionId: "5f16b059e2ba2d1cc0eb7f31",
                isDate: false,
                isTime: false,
                isAmount: false,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: false,
                createdAt: "2020-07-21T10:30:01.101Z",
                updatedAt: "2020-07-21T10:30:01.101Z",
                __v: 0
            },
            {
                _id: "5f16c3b8f775003920a5a91d",
                name: "Customer disconnected the call",
                dispostionId: "5f16b059e2ba2d1cc0eb7f31",
                isDate: false,
                isTime: false,
                isAmount: false,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: false,
                createdAt: "2020-07-21T10:30:16.203Z",
                updatedAt: "2020-07-21T10:30:16.203Z",
                __v: 0
            },
            {
                _id: "5f16c3c0f775003920a5a91e",
                name: "Call blocked",
                dispostionId: "5f16b059e2ba2d1cc0eb7f31",
                isDate: false,
                isTime: false,
                isAmount: false,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: false,
                createdAt: "2020-07-21T10:30:24.779Z",
                updatedAt: "2020-07-21T10:30:24.779Z",
                __v: 0
            }
        ]
    },
    {
        _id: "5f16b0a7e2ba2d1cc0eb7f32",
        name: "DISPUTE",
        createdAt: "2020-07-21T09:08:55.116Z",
        updatedAt: "2020-07-21T09:08:55.116Z",
        __v: 0,
        shortCode: "DP",
        codedispositions: [
            {
                _id: "5f16c402f775003920a5a91f",
                name: "Already paid",
                dispostionId: "5f16b0a7e2ba2d1cc0eb7f32",
                isDate: false,
                isTime: false,
                isAmount: false,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: false,
                createdAt: "2020-07-21T10:31:30.306Z",
                updatedAt: "2020-07-21T10:31:30.306Z",
                __v: 0
            },
            {
                _id: "5f16c40ff775003920a5a920",
                name: "Abusive language",
                dispostionId: "5f16b0a7e2ba2d1cc0eb7f32",
                isDate: false,
                isTime: false,
                isAmount: false,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: false,
                createdAt: "2020-07-21T10:31:43.412Z",
                updatedAt: "2020-07-21T10:31:43.412Z",
                __v: 0
            },
            {
                _id: "5f16c41ef775003920a5a921",
                name: "Supervisory assistance",
                dispostionId: "5f16b0a7e2ba2d1cc0eb7f32",
                isDate: false,
                isTime: false,
                isAmount: false,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: false,
                createdAt: "2020-07-21T10:31:58.315Z",
                updatedAt: "2020-07-21T10:31:58.315Z",
                __v: 0
            },
            {
                _id: "5f16c42ff775003920a5a922",
                name: "Service dispute",
                dispostionId: "5f16b0a7e2ba2d1cc0eb7f32",
                isDate: false,
                isTime: false,
                isAmount: false,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: false,
                createdAt: "2020-07-21T10:32:15.388Z",
                updatedAt: "2020-07-21T10:32:15.388Z",
                __v: 0
            },
            {
                _id: "5f16c43bf775003920a5a923",
                name: "Customer deceased",
                dispostionId: "5f16b0a7e2ba2d1cc0eb7f32",
                isDate: false,
                isTime: false,
                isAmount: false,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: false,
                createdAt: "2020-07-21T10:32:27.379Z",
                updatedAt: "2020-07-21T10:32:27.379Z",
                __v: 0
            },
            {
                _id: "5f16cbd649e1021080448752",
                name: "Language barrier",
                dispostionId: "5f16b0a7e2ba2d1cc0eb7f32",
                isDate: false,
                isTime: false,
                isAmount: false,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: true,
                preferedLanguage: [
                    "English",
                    "Tamil ",
                    "Telgu",
                    "Kannada",
                    "Malyalam",
                    "Gujrati",
                    "Bengali",
                    "Oriya",
                    "Hindi",
                    "Marathi",
                    "Punjabi"
                ],
                createdAt: "2020-07-21T11:04:54.560Z",
                updatedAt: "2020-07-21T11:04:54.560Z",
                __v: 0
            }
        ]
    },
    {
        _id: "5f16b0d3e2ba2d1cc0eb7f33",
        name: "CALLBACK",
        createdAt: "2020-07-21T09:09:39.414Z",
        updatedAt: "2020-07-21T09:09:39.414Z",
        __v: 0,
        shortCode: "CB",
        codedispositions: [
            {
                _id: "5f16cc9f174bcc3d74712f63",
                name: "Customer abruptly disconnected the call",
                dispostionId: "5f16b0d3e2ba2d1cc0eb7f33",
                isDate: true,
                isAmount: false,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: false,
                preferedLanguage: [],
                createdAt: "2020-07-21T11:08:15.404Z",
                updatedAt: "2020-07-21T11:08:15.404Z",
                __v: 0,
                isTime: true
            },
            {
                _id: "5f16ccb0174bcc3d74712f64",
                name: "Waiver/Settlement/SOA request",
                dispostionId: "5f16b0d3e2ba2d1cc0eb7f33",
                isDate: true,
                isAmount: false,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: false,
                preferedLanguage: [],
                createdAt: "2020-07-21T11:08:32.064Z",
                updatedAt: "2020-07-21T11:08:32.064Z",
                __v: 0,
                isTime: true
            },
            {
                _id: "5f55c16fec84dc2ea453d434",
                name: "Customer Request",
                dispostionId: "5f16b0d3e2ba2d1cc0eb7f33",
                isDate: true,
                isTime: true,
                isAmount: false,
                isRefNo: false,
                isScreenshot: false,
                isLanguage: false,
                preferedLanguage: [],
                createdAt: "2020-09-07T05:13:19.858Z",
                updatedAt: "2020-09-07T05:13:19.858Z",
                __v: 0
            },
            {
                _id: "60c1a3c55cb44778281bc6dd",
                name: "Request for Transfer Letter",
                dispostionId: "5f16b0d3e2ba2d1cc0eb7f33",
                isDate: true,
                isTime: true,
                isAmount: false,
                isRefNo: false,
                isScreenshot: false,
                isLanguage: false,
                isField: false,
                preferedLanguage: [],
                createdAt: "2021-06-10T05:31:49.812Z",
                updatedAt: "2021-06-10T05:31:49.812Z",
                __v: 0
            },
            {
                _id: "60c1a3f75cb44778281bc6e1",
                name: "Request for SOA",
                dispostionId: "5f16b0d3e2ba2d1cc0eb7f33",
                isDate: true,
                isTime: true,
                isAmount: false,
                isRefNo: false,
                isScreenshot: false,
                isLanguage: false,
                isField: false,
                preferedLanguage: [],
                createdAt: "2021-06-10T05:32:39.500Z",
                updatedAt: "2021-06-10T05:32:39.500Z",
                __v: 0
            },
            {
                _id: "60c1a41c5cb44778281bc6e8",
                name: "Request for interest/ penal waiver ",
                dispostionId: "5f16b0d3e2ba2d1cc0eb7f33",
                isDate: true,
                isTime: true,
                isAmount: false,
                isRefNo: false,
                isScreenshot: false,
                isLanguage: false,
                isField: false,
                preferedLanguage: [],
                createdAt: "2021-06-10T05:33:16.865Z",
                updatedAt: "2021-06-10T05:33:16.865Z",
                __v: 0
            },
            {
                _id: "60c1a4375cb44778281bc6ea",
                name: "Request for payment confirmation",
                dispostionId: "5f16b0d3e2ba2d1cc0eb7f33",
                isDate: true,
                isTime: true,
                isAmount: true,
                isRefNo: false,
                isScreenshot: false,
                isLanguage: false,
                isField: false,
                preferedLanguage: [],
                createdAt: "2021-06-10T05:33:43.222Z",
                updatedAt: "2021-06-10T05:33:43.222Z",
                __v: 0
            }
        ]
    },
    {
        _id: "5f16b0dde2ba2d1cc0eb7f34",
        name: "BPTP",
        createdAt: "2020-07-21T09:09:49.919Z",
        updatedAt: "2020-07-21T09:09:49.919Z",
        __v: 0,
        shortCode: "BPTP",
        codedispositions: [
            {
                _id: "5f16ccfe174bcc3d74712f65",
                name: "PTP postponed",
                dispostionId: "5f16b0dde2ba2d1cc0eb7f34",
                isDate: true,
                isAmount: true,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: false,
                preferedLanguage: [],
                createdAt: "2020-07-21T11:09:50.268Z",
                updatedAt: "2020-07-21T11:09:50.268Z",
                __v: 0
            }
        ]
    },
    {
        _id: "5f16b0f2e2ba2d1cc0eb7f35",
        name: "NRTP_or_RTP",
        createdAt: "2020-07-21T09:10:10.174Z",
        updatedAt: "2020-07-21T09:10:10.174Z",
        __v: 0,
        shortCode: "NRTP",
        codedispositions: [
            {
                _id: "5f16cd2f174bcc3d74712f66",
                name: "Medical issues",
                dispostionId: "5f16b0f2e2ba2d1cc0eb7f35",
                isDate: false,
                isAmount: false,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: false,
                preferedLanguage: [],
                createdAt: "2020-07-21T11:10:39.759Z",
                updatedAt: "2020-07-21T11:10:39.759Z",
                __v: 0
            },
            {
                _id: "5f16cd42174bcc3d74712f67",
                name: "Out of Station",
                dispostionId: "5f16b0f2e2ba2d1cc0eb7f35",
                isDate: false,
                isAmount: false,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: false,
                preferedLanguage: [],
                createdAt: "2020-07-21T11:10:58.460Z",
                updatedAt: "2020-07-21T11:10:58.460Z",
                __v: 0
            },
            {
                _id: "5f16cd50174bcc3d74712f68",
                name: "Financial inability",
                dispostionId: "5f16b0f2e2ba2d1cc0eb7f35",
                isDate: false,
                isAmount: false,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: false,
                preferedLanguage: [],
                createdAt: "2020-07-21T11:11:12.475Z",
                updatedAt: "2020-07-21T11:11:12.475Z",
                __v: 0
            },
            {
                _id: "5f16cd5d174bcc3d74712f69",
                name: "Intentional defaulter",
                dispostionId: "5f16b0f2e2ba2d1cc0eb7f35",
                isDate: false,
                isAmount: false,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: false,
                preferedLanguage: [],
                createdAt: "2020-07-21T11:11:25.161Z",
                updatedAt: "2020-07-21T11:11:25.161Z",
                __v: 0
            },
            {
                _id: "5f16cd68174bcc3d74712f6a",
                name: "Others",
                dispostionId: "5f16b0f2e2ba2d1cc0eb7f35",
                isDate: false,
                isAmount: false,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: false,
                preferedLanguage: [],
                createdAt: "2020-07-21T11:11:36.518Z",
                updatedAt: "2020-07-21T11:11:36.518Z",
                __v: 0
            }
        ]
    },
    {
        _id: "5f16b101e2ba2d1cc0eb7f36",
        name: "PAID",
        createdAt: "2020-07-21T09:10:25.776Z",
        updatedAt: "2020-07-21T09:10:25.776Z",
        __v: 0,
        shortCode: "PD",
        codedispositions: [
            {
                _id: "5f16cd82174bcc3d74712f6b",
                name: "Paid online",
                dispostionId: "5f16b101e2ba2d1cc0eb7f36",
                isDate: true,
                isAmount: true,
                isReferenceNumber: true,
                isScreenshot: true,
                isLanguage: false,
                preferedLanguage: [],
                createdAt: "2020-07-21T11:12:02.587Z",
                updatedAt: "2020-07-21T11:12:02.587Z",
                __v: 0
            },
            {
                _id: "5f16cd8f174bcc3d74712f6c",
                name: "Paid cash deposit",
                dispostionId: "5f16b101e2ba2d1cc0eb7f36",
                isDate: true,
                isAmount: true,
                isReferenceNumber: true,
                isScreenshot: true,
                isLanguage: false,
                preferedLanguage: [],
                createdAt: "2020-07-21T11:12:15.487Z",
                updatedAt: "2020-07-21T11:12:15.487Z",
                __v: 0
            },
            {
                _id: "60817e00d38fcc6abc25486d",
                name: "Bank Paid",
                dispostionId: "5f16b101e2ba2d1cc0eb7f36",
                isDate: true,
                isTime: true,
                isAmount: true,
                isRefNo: false,
                isScreenshot: true,
                isLanguage: false,
                isField: false,
                preferedLanguage: [],
                createdAt: "2021-04-22T13:45:36.058Z",
                updatedAt: "2021-04-22T13:45:36.058Z",
                __v: 0
            },
            {
                _id: "648c035cf7ce2c545edb9514",
                name: "Paid_SR",
                dispostionId: "5f16b101e2ba2d1cc0eb7f36",
                isDate: true,
                isAmount: true,
                isReferenceNumber: true,
                isScreenshot: true,
                isLanguage: false,
                preferedLanguage: [],
                createdAt: "2020-07-21T11:12:02.587Z",
                updatedAt: "2020-07-21T11:12:02.587Z",
                __v: 0
            },
            {
                _id: "648c0374f7ce2c545edb9516",
                name: "Paid_FR",
                dispostionId: "5f16b101e2ba2d1cc0eb7f36",
                isDate: true,
                isAmount: true,
                isReferenceNumber: true,
                isScreenshot: true,
                isLanguage: false,
                preferedLanguage: [],
                createdAt: "2020-07-21T11:12:02.587Z",
                updatedAt: "2020-07-21T11:12:02.587Z",
                __v: 0
            }
        ]
    },
    {
        _id: "5f16b110e2ba2d1cc0eb7f37",
        name: "PART_PAID",
        createdAt: "2020-07-21T09:10:40.725Z",
        updatedAt: "2020-07-21T09:10:40.725Z",
        __v: 0,
        shortCode: "PPD",
        codedispositions: [
            {
                _id: "5f1970fd0dec271774a78452",
                name: "Paid online",
                dispostionId: "5f16b110e2ba2d1cc0eb7f37",
                isDate: true,
                isAmount: true,
                isRefNo: true,
                isScreenshot: true,
                isLanguage: false,
                preferedLanguage: [],
                createdAt: "2020-07-23T11:14:06.072Z",
                updatedAt: "2020-07-23T11:14:06.072Z",
                __v: 0,
                isReferenceNumber: true
            },
            {
                _id: "5f1971120dec271774a78453",
                name: "Paid cash deposit",
                dispostionId: "5f16b110e2ba2d1cc0eb7f37",
                isDate: true,
                isAmount: true,
                isRefNo: true,
                isScreenshot: true,
                isLanguage: false,
                preferedLanguage: [],
                createdAt: "2020-07-23T11:14:26.876Z",
                updatedAt: "2020-07-23T11:14:26.876Z",
                __v: 0
            }
        ]
    },
    {
        _id: "5f16b133e2ba2d1cc0eb7f38",
        name: "PTP",
        createdAt: "2020-07-21T09:11:15.046Z",
        updatedAt: "2020-07-21T09:11:15.046Z",
        __v: 0,
        shortCode: "PTP",
        codedispositions: [
            {
                _id: "5f16cf09174bcc3d74712f6f",
                name: "PTP online",
                dispostionId: "5f16b133e2ba2d1cc0eb7f38",
                isDate: true,
                isAmount: true,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: false,
                preferedLanguage: [],
                createdAt: "2020-07-21T11:18:33.975Z",
                updatedAt: "2020-07-21T11:18:33.975Z",
                __v: 0,
                isTime: true
            },
            {
                _id: "5f16cf13174bcc3d74712f70",
                name: "PTP cash deposit",
                dispostionId: "5f16b133e2ba2d1cc0eb7f38",
                isDate: true,
                isAmount: true,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: false,
                preferedLanguage: [],
                createdAt: "2020-07-21T11:18:43.529Z",
                updatedAt: "2020-07-21T11:18:43.529Z",
                __v: 0,
                isTime: true
            },
            {
                _id: "6091229828854d792d15acf3",
                name: "PTP Callback",
                dispostionId: "5f16b133e2ba2d1cc0eb7f38",
                isDate: true,
                isTime: true,
                isAmount: true,
                isRefNo: false,
                isScreenshot: false,
                isLanguage: false,
                isField: false,
                preferedLanguage: [],
                createdAt: "2021-05-04T10:31:52.100Z",
                updatedAt: "2021-05-04T10:31:52.100Z",
                __v: 0
            },
            {
                _id: "609122a428854d792d15acf5",
                name: "PTP NC",
                dispostionId: "5f16b133e2ba2d1cc0eb7f38",
                isDate: true,
                isTime: true,
                isAmount: true,
                isRefNo: false,
                isScreenshot: false,
                isLanguage: false,
                isField: false,
                preferedLanguage: [],
                createdAt: "2021-05-04T10:32:04.937Z",
                updatedAt: "2021-05-04T10:32:04.937Z",
                __v: 0
            },
            {
                _id: "648c03a2f7ce2c545edb951a",
                name: "PTP_SR",
                dispostionId: "5f16b133e2ba2d1cc0eb7f38",
                isDate: true,
                isAmount: true,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: false,
                preferedLanguage: [],
                createdAt: "2020-07-21T11:18:33.975Z",
                updatedAt: "2020-07-21T11:18:33.975Z",
                __v: 0,
                isTime: true
            },
            {
                _id: "648c03b6f7ce2c545edb951c",
                name: "PTP_FR",
                dispostionId: "5f16b133e2ba2d1cc0eb7f38",
                isDate: true,
                isAmount: true,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: false,
                preferedLanguage: [],
                createdAt: "2020-07-21T11:18:33.975Z",
                updatedAt: "2020-07-21T11:18:33.975Z",
                __v: 0,
                isTime: true
            }
        ]
    },
    {
        _id: "5f16b157e2ba2d1cc0eb7f39",
        name: "REFERENCE_CALLING",
        createdAt: "2020-07-21T09:11:51.831Z",
        updatedAt: "2020-07-21T09:11:51.831Z",
        __v: 0,
        shortCode: "RC",
        codedispositions: [
            {
                _id: "5f16cf82174bcc3d74712f71",
                name: "Doesn't know the customer",
                dispostionId: "5f16b157e2ba2d1cc0eb7f39",
                isDate: false,
                isAmount: false,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: false,
                preferedLanguage: [],
                createdAt: "2020-07-21T11:20:34.004Z",
                updatedAt: "2020-07-21T11:20:34.004Z",
                __v: 0
            },
            {
                _id: "5f16cf98174bcc3d74712f72",
                name: "LM with relative",
                dispostionId: "5f16b157e2ba2d1cc0eb7f39",
                isDate: false,
                isAmount: false,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: false,
                preferedLanguage: [],
                createdAt: "2020-07-21T11:20:56.431Z",
                updatedAt: "2020-07-21T11:20:56.431Z",
                __v: 0
            },
            {
                _id: "5f16cfa3174bcc3d74712f73",
                name: "Not contacted/Busy/RNR",
                dispostionId: "5f16b157e2ba2d1cc0eb7f39",
                isDate: false,
                isAmount: false,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: false,
                preferedLanguage: [],
                createdAt: "2020-07-21T11:21:07.182Z",
                updatedAt: "2020-07-21T11:21:07.182Z",
                __v: 0
            },
            {
                _id: "5f16cfae174bcc3d74712f74",
                name: "Customer found",
                dispostionId: "5f16b157e2ba2d1cc0eb7f39",
                isDate: false,
                isAmount: false,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: false,
                preferedLanguage: [],
                createdAt: "2020-07-21T11:21:18.554Z",
                updatedAt: "2020-07-21T11:21:18.554Z",
                __v: 0
            },
            {
                _id: "5f16cfbc174bcc3d74712f75",
                name: "Other",
                dispostionId: "5f16b157e2ba2d1cc0eb7f39",
                isDate: false,
                isAmount: false,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: false,
                preferedLanguage: [],
                createdAt: "2020-07-21T11:21:32.123Z",
                updatedAt: "2020-07-21T11:21:32.123Z",
                __v: 0
            }
        ]
    },
    {
        _id: "5f16b192e2ba2d1cc0eb7f3a",
        name: "AUTO_DEBIT_REQUEST",
        createdAt: "2020-07-21T09:12:50.386Z",
        updatedAt: "2020-07-21T09:12:50.386Z",
        __v: 0,
        shortCode: "ADR",
        codedispositions: [
            {
                _id: "65e07a29f606f639c271e508",
                name: "AUTO_DEBIT_REQUEST",
                dispostionId: "5f16b192e2ba2d1cc0eb7f3a",
                isDate: false,
                isTime: false,
                isAmount: false,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: false,
                createdAt: "2024-02-29T10:28:51.638Z",
                updatedAt: "2024-02-29T10:28:51.638Z",
                __v: 0
            }
        ]
    },
    {
        _id: "5f48f6c4a01918e3bf065625",
        name: "RINGING_NO_RESPONSE",
        createdAt: "2020-07-21T09:12:50.386Z",
        updatedAt: "2020-07-21T09:12:50.386Z",
        __v: 0,
        shortCode: "RNR",
        codedispositions: [
            {
                _id: "5f48f7a2a01918e3bf065626",
                name: "Ringing No Response",
                dispostionId: "5f48f6c4a01918e3bf065625",
                isDate: false,
                isTime: false,
                isAmount: false,
                isReferenceNumber: false,
                isScreenshot: false,
                isLanguage: false,
                createdAt: "2020-07-21T10:27:11.936Z",
                updatedAt: "2020-07-21T10:27:11.936Z",
                __v: 0
            }
        ]
    },
    {
        _id: "62fce3b6adcb71c798af96ba",
        name: "SETTLEMENT_REQUEST",
        shortCode: "SR",
        codedispositions: [
            {
                _id: "62fce49cadcb71c798af9800",
                name: "single_installment",
                dispostionId: "62fce3b6adcb71c798af96ba",
                isDate: false,
                waiverOnPOS: true,
                waiverOnTOS: true,
                isSettlementAmount: true
            },
            {
                _id: "62fce4e3adcb71c798af9853",
                name: "multiple_installment",
                dispostionId: "62fce3b6adcb71c798af96ba",
                isSettlementAmount: true,
                waiverOnPOS: true,
                waiverOnTOS: true,
                noOfInstallments: true,
                Reason: true,
                installments: true
            }
        ]
    },
    {
        _id: "62fce3bfadcb71c798af96c7",
        name: "FORECLOSURE_REQUEST",
        shortCode: "FR",
        codedispositions: [
            {
                _id: "62fce4b1adcb71c798af9817",
                name: "single_installment",
                dispostionId: "62fce3bfadcb71c798af96c7",
                date: false,
                isForeclosure: true
            },
            {
                _id: "62fce4ccadcb71c798af9837",
                name: "multiple_installment",
                dispostionId: "62fce3bfadcb71c798af96c7",
                isAmount: false,
                isSettlementAmount: false,
                noOfInstallments: true,
                Reason: true,
                installments: true,
                isForeclosure: true
            }
        ]
    }
]

const initialState = {
    activeLoans: [],
    callhistories: [],
    callhistory: {},
    dispositions: [],
    partnerLoans: [],
    dispActiveLoans: [],
    distBucketClient: [],
    dailycallreports: [],
    isPredictive: 0,
    currentPage: 0,
    perPage: 0,
    totalItems: 0,
    totalPages: 0,
    allDispositions: [],
    profileList: [],
    monthsCalcList: [],
    searchHistory: {},
    calldisposition: {},
    isLast: false,
    allNotifications: [],
    allClients: [],
    managerReports: [],
    allocationReports: [],
    workableReports: [],
    subsType: '',
    additionalReports: [],
    borrowerProfileData: [],
    letterDownload: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
    switch (type) {

        case callManagementConstants.GET_ACTIVE_CALL:
            console.log('coming in ActiveLoANS')
            // var ids = new Set(state.activeLoans.map(d => d._id));
            // console.log("payload in reducer",payload.filter(d => !ids.has(d._id)));
            // var merged = [...state.activeLoans, ...payload.filter(d => !ids.has(d._id))];
            // console.log(`merged Arrays `, merged)
            return {
                ...state,
                activeLoans: payload,
                isLast: (payload.length === 0) ? true : false
            }
        case callManagementConstants.GET_DISP_ACTIVE_CALL:
            console.log('coming in disp', payload)
            return { ...state, dispActiveLoans: payload }
        case callManagementConstants.GET_DIST_CLIENT_BUCKET:
            return { ...state, distBucketClient: payload }
        case callManagementConstants.GET_PARTNER_ALL:
            return { ...state, partnerLoans: payload }

        case callManagementConstants.GET_DISPOSITION_CODE:
            return { ...state, dispositions: payload }

        case callManagementConstants.GET_DISPOSITION_ALL:
            return { ...state, allDispositions: payload }

        case callManagementConstants.GET_ALLOCATION_REPORTS:
            console.log('entering in reducer')
            return { ...state, allocationReports: payload }

        case callManagementConstants.GET_WORKABLE_REPORTS:
            console.log('entering in reducer')
            return { ...state, workableReports: payload }
        case callManagementConstants.subscriptionType:
            console.log('entering in reducer')
            return { ...state, subsType: payload }
        case callManagementConstants.GET_ADDITIONAL_REPORTS:
            console.log('entering in reducer')
            return { ...state, additionalReports: payload }
        case callManagementConstants.GET_CLIENTS_BY_MANAGER:
            return { ...state, allClients: payload }
        case callManagementConstants.CREATE_PROFILE_LIST:
            return { ...state, profileList: payload }
        case callManagementConstants.CREATE_MONTHS_CALC_LIST:
            return { ...state, monthsCalcList: payload }
        case callManagementConstants.GET_MANAGER_REPORTS:
            return { ...state, managerReports: payload }
        case callManagementConstants.BORROWER_PROFILE:
            return { ...state, borrowerProfileData: payload }
        case callManagementConstants.GET_NOTIFICATION_ALL:
            return { ...state, allNotifications: payload }

        case callManagementConstants.GET_LETTER_DOWNLOAD:
            return { ...state, letterDownload: payload }

        case callManagementConstants.GET_REPORTS_ALL:
            return { ...state, allReports: payload }

        case callManagementConstants.DELETE_NOTIFICATION:
            return { ...state, allNotifications: state.allNotifications.filter(loan => !payload.includes(loan._id)) }

        case callManagementConstants.DELETE_NOTIFICATION_APPROVE:
            return { ...state, allNotifications: state.allNotifications.filter(loan => !payload.includes(loan._id)) }

        case callManagementConstants.DELETE_CALL_DISPOSITION:
            return { ...state, callhistories: state.callhistories.filter(s => s._id !== payload._id) }

        case callManagementConstants.GET_CALL_DISPOSITION:
            return { ...state, calldisposition: payload }

        case callManagementConstants.DAILY_CALL_REPORTS:
            return { ...state, dailycallreports: payload }

        case callManagementConstants.PREDICTIVE_CALL_DISPOSTION:
            console.log('reducer inner ', payload);
            return { ...state, isPredictive: payload }

        case callManagementConstants.SEARCH_HISTORY:
            console.log('reducer inner ', payload);
            return {
                ...state, searchHistory: payload,
            }
        case callManagementConstants.TOATAL_PAGES:
            return { ...state, totalPages: payload }

        case callManagementConstants.TOATAL_ITEMS:
            return { ...state, totalItems: payload }

        case callManagementConstants.CURRENT_PAGE:
            return { ...state, currentPage: payload }

        case callManagementConstants.PER_PAGE:
            return { ...state, perPage: payload }


        case callManagementConstants.CREATE_CALL_STATUS:

            // const aloans = state.searchHistory.activeLoans
            const activeLoans = (state.searchHistory.activeLoans && state.searchHistory.activeLoans.length > 0) ? (state.searchHistory.activeLoans.map((ch, index) => {
                // return ch   
                if (ch._id === payload.loanId) {
                    return payload;
                } else {
                    return ch;
                }

            })) : []
            return {
                ...state,
                searchHistory: {
                    ...state.searchHistory,
                    activeLoans: activeLoans
                }
            }
        // let tes = {...state, callhistories : [ ...state.callhistories, ...[payload] ]};
        // console.log(`call reducer`, tes);
        // return {
        //     ...state,
        //     callhistory : payload,
        //     callhistories : [
        //         ...state.callhistories,
        //         ...[payload]
        //     ]

        // }

        case callManagementConstants.UPDATE_CALL_STATUS:
            let callhistories = state.callhistories.map((ch, index) => {
                if (ch._id == payload._id) {
                    return payload;
                } else {
                    return ch;
                }
            })

            let tess = { ...state, callhistory: payload, callhistories: callhistories };
            console.log(`update call reducer`, tess);



            return {
                ...state,
                callhistories: callhistories
            }

        case callManagementConstants.GET_CALL_HISTORY:
            return { ...state, callhistories: Object.keys(payload).length > 0 ? payload : [] }

        default:
            return state
    }
}
