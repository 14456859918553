export const getProfileDetail = () => {
  return JSON.parse(localStorage.getItem("collektoUser")) || {};
};

export const loginUser = () => {
  const { role } = getProfileDetail();
  return role;
};
export const getLoginTenant = () => {
  const tenant = getProfileDetail()?.tenant;
  return tenant;
};

export const getLoginSub = () => {
  const sub = getProfileDetail()?.id;
  return sub;
};
