import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { logout } from "../../actions/authenticationAction";
import { connect } from "react-redux";
import {
  getAllAlerts,
  handleError,
  handleInfo,
  handleClickError,
  handleModal,
  getCollectorProcess,
} from "../../actions/collectorAction";
import SimpleReactValidator from "simple-react-validator";
import "./AltHeader.scss";
import axios from "axios";
import { getMissedAlerts } from "../../actions/collectorAction";
import AlertTable from "./../Notifications/Table";
import Axios from "axios";
import { API_URL, JAVA_API_URL, collectorConstants } from "../../constants/ActionTypes";
import apiAxios from "../../api";
import { alertActions } from "../../actions";

const { REACT_APP_JAVA_API_URL } = process.env;
const collektoUser = JSON.parse(localStorage.getItem("collektoUser"));

const { username, role, location } = collektoUser || {};

class AltHeader extends Component {
  isModalVisible = false;
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    const { collektoUser } = this.props.authentication;
    const { manualProcess, assignedPredictiveProcess } = this.props.collectors.collectorProcess || {};
    this.manualProcess = manualProcess;
    this.assignedPredictiveProcess = assignedPredictiveProcess;
    const agentActive = localStorage.getItem("agentActive");
    //  console.log(`header this.props.authentication`, this.props.authentication)
    document.body.classList.add("mini-sidebar");
    if (this.alertsInterval) {
      clearInterval(this.alertsInterval);

    }
    this.alertsInterval =
      collektoUser?.role === "COLLECTOR"
        ? setInterval(() => this.props.getAllAlerts(collektoUser?._id), 120000)
        : null;
    if (collektoUser && collektoUser?.role === "CUSTOMER") {
      // document.body.classList.add('single-column');
      // document.body.classList.remove('mini-sidebar');
    }

    if (collektoUser?.role == "COLLECTOR") {
      this.props.getMissedAlerts(collektoUser._id);
    }
    // let {alerts} = props?.collectors;
    // alerts = alerts? alerts: [];
    // const alert = {
    //     "alertType": "CallBack",
    //     "loanId": "6451febd12c76f65705e197a",
    //     "customerName": "Harinath Yadav",
    //     "mobileNumber": "9435575264",
    //     "alertDate": "2023-05-14",
    //     "alertTime": "03:37",
    //     "alertDateTime": "2023-05-14T03:37:00",
    //     "createdAt": "2023-05-14T03:31:02.415",
    //     "updatedAt": "2023-05-14T03:31:02.415"
    // }
    // alerts.push(alert);
    // alerts.forEach(el=>{
    //     const {alertType, customerName, alertTime} = el;
    //     handleInfo(`Alert for ${customerName} for ${alertType} on ${alertTime}`);
    // });

    //  this.handleMenuClick = this.handleMenuClick.bind(this)
    this.state = {
      isModalVisible: false, // Add isModalVisible to component state
      showMiniSideBar: true,
      agentActive: (!agentActive ? "true" : agentActive == "true" ? "true" : "false"),
    };
  }
  static propTypes = {
    authentication: PropTypes.object.isRequired,
  };

  componentDidUpdate() {
    const { manualProcess, assignedPredictiveProcess } = this.props.collectors.collectorProcess || {};
    this.manualProcess = manualProcess;
    this.assignedPredictiveProcess = assignedPredictiveProcess;
  }

  componentDidMount() {
    if (role === "COLLECTOR" && location == "D") {
      this.props.getCollectorProcess(username);
    }
    // document.body.classList.add('mini-sidebar');
    // this.setState({showMiniSideBar: true})
  }

  componentWillUnmount() {
    clearInterval(this.alertsInterval);
  }

  handleMenuClick = () => {
    console.log(`this click`);
    // if (document.body.classList.contains('mini-sidebar')) {
    if (this.state.showMiniSideBar) {
      document.body.classList.remove("mini-sidebar");
      this.setState({ showMiniSideBar: false });
    } else {
      document.body.classList.add("mini-sidebar");
      this.setState({ showMiniSideBar: true });
    }
    // if(this.state.showMiniSideBar){
    //     document.body.classList.remove('mini-sidebar');
    //     this.setState({ showMiniSideBar: false });
    // }else{
    //     document.body.classList.add('mini-sidebar');

    // this.setState({ showMiniSideBar: true });
    // }
  };

  logout = () => {
    const { collektoUser } = this.props.authentication;
    console.log(`header collekto user `, collektoUser.username);
    apiAxios.post(`${REACT_APP_JAVA_API_URL}/api/v2/dialer/logout`, {
      username: collektoUser.username,
    });
    this.props.logout();
  };
  handleAlertCountClick = () => {
    console.log("coming handleAlertCountClick");
    this.props.dispatch({
      type: collectorConstants.SHOWALERTSMODAL,
      payload: true,
    });
  };

  handleCloseModal = (value) => {
    this.props.handleCloseModal(value);
  };

  changeAgentStatus = async () => {
    const predictive = localStorage.getItem("isPredictive");
    const agentStatus = localStorage.getItem("agentActive");
    let process;
    if (predictive && predictive !== "false") {
      process = this.assignedPredictiveProcess;
    }
    else {
      process = this.manualProcess;
    }
    const payload = {
      username,
      breakName: "lunch",
      processName: process
    }
    if (agentStatus == "true" || agentStatus == null) {
      await axios.post(`${JAVA_API_URL}/api/v2/dialer/break`, payload).then(res => {
        res = res.data;
        if (res.success) {
          alertActions.info("Agent is in break mode");
          this.setState({ agentActive: "false" });
          localStorage.setItem('agentActive', "false");
        }
      }).catch(e => {
        alertActions.error(e);
      })
    }
    else {
      await axios.post(`${JAVA_API_URL}/api/v2/dialer/readyCall`, { username }).then(res => {
        res = res.data;
        if (res.success) {
          alertActions.info("Agent is in idle mode");
          this.setState({ agentActive: "true" });
          localStorage.setItem('agentActive', "true");
        }
      }).catch(e => {
        alertActions.error(e);
      })
    }
  }

  render() {
    const { collektoUser } = this.props.authentication;
    // this.alertsInterval = collektoUser?.role==="COLLECTOR"? setInterval(this.props.getAllAlerts(collektoUser?._id), 300000) : null;
    let { alertsCount, missedAlerts } = this.props.collectors;
    if (!alertsCount) {
      alertsCount = 0;
    }
    const sampleAlert = missedAlerts?.length ? missedAlerts[0] : {};
    const alertCols = [];
    for (let k in sampleAlert) {
      alertCols.push({
        dataField: k,
        text: k.toUpperCase(),
      });
    }
    const alertsRowEvent = {
      onClick: async (e, row, rowIndex) => {
        const { _id } = this.props?.authentication?.collektoUser;
        console.log("current row is", row);
        const axiosReq = Axios.create({
          headers: {
            collectorId: _id,
          },
        });
        console.log("expected row is: ", row);
        await axiosReq.put(
          `${REACT_APP_JAVA_API_URL}/api/v2/alerts/${row.id}/deactivate`
        );
        this.props.history.push(`/call-management`, {
          _id: row.loanId,
          Loan_ID: row.loanNumber,
        });
        // window.location.reload();
      },
    };
    const handleAlertCountClick = () => (dispatch) => {
      dispatch({ type: collectorConstants.SHOWALERTSMODAL, payload: true });
    };

    const handleCloseModal = () => {
      this.setState({ isModalVisible: false }); // Set isModalVisible to true when clicked
    };
    return (
      <header className="topbar header-style-1">
        <nav className="">
          <div className="nav-style-1">
            <Link className="navbar-brand" to="/">
              <img src="./assets/images/logo-symbol_1.png"></img>
              <img src="./assets/images/logo 1.png"></img>
            </Link>
            <div className="right-header d-flex align-items-center">
              {
                role === "COLLECTOR" && location === "D" && < div className="d-flex align-items-center">
                  <span className="m-0 user-detail">Ready: </span>
                  <div class="custom-control1 custom-switch inline-block ml-2">
                    <input checked={this.state.agentActive == "true" ? true : false} onChange={this.changeAgentStatus} type="checkbox" class="custom-control-input1" id="agent-status" />
                    <label class="custom-control-label1" htmlFor="agent-status"> </label>
                  </div>
                </div>
              }
              <div className="d-flex h-10 align-items-center dropdown u-pro">
                <a
                  className="nav-link  waves-effect waves-dark"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="user-detail">
                    <span>
                      {collektoUser?.role === "COLLECTOR"
                        ? "Agent"
                        : collektoUser?.role === "MANAGER"

                          ? "Manager"
                          : collektoUser?.role === "ADMIN"
                            ? "Admin"
                            : collektoUser?.role === "CUSTOMER"
                              ? "Super Manager"
                              : "User"}

                    </span>
                    <span>
                      &nbsp;
                      {collektoUser?.role === "CUSTOMER"
                        ? collektoUser?.client
                        : collektoUser?.fullName}
                    </span>
                  </span>
                  <img
                    src={`./assets/images/Frame 47.png`}
                    className="profile-pic img-fluid"
                    alt="user"
                  />
                </a>

                <div className="dropdown-menu">
                  {collektoUser?.role === "ADMIN" && (
                    <Link to={`/myprofile`} className="dropdown-item">
                      <img width="15px" src="assets/images/userProfile.svg" />{" "}
                      My Profile
                    </Link>
                  )}
                  {collektoUser?.role === "MANAGER" && (
                    <Link to={`/profile`} className="dropdown-item">
                      <img width="15px" src="assets/images/userProfile.svg" />{" "}
                      My Profile
                    </Link>
                  )}
                  {collektoUser?.role === "COLLECTOR" && (
                    <Link to={`/my-profile`} className="dropdown-item">
                      <img width="15px" src="assets/images/userProfile.svg" />{" "}
                      My Profile
                    </Link>
                  )}
                  {collektoUser?.role === "CUSTOMER" && (
                    <Link to={`/my-profile`} className="dropdown-item">
                      <img width="15px" src="assets/images/userProfile.svg" />{" "}
                      My Profile
                    </Link>
                  )}
                  <a
                    dangerouslysethref="javascript:void(0);"
                    onClick={this.logout}
                    className="dropdown-item"
                  >
                    <img width="15px" src="assets/images/power-off.svg" />{" "}
                    Logout
                  </a>

                  {/* text*/}
                </div>
              </div>
            </div>
            {collektoUser?.role === "COLLECTOR" && (
              <span
                className="alertCount2"
                title="alerts count"
                style={{ zIndex: 1000, cursor: "pointer" }}
                onClick={(e) => this.handleCloseModal(true)}
              >
                {alertsCount}
              </span>
            )}
          </div>
        </nav>
      </header >
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.authentication,
  collectors: state.collectors,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  getMissedAlerts: (collectorId) => dispatch(getMissedAlerts(collectorId)),
  getAllAlerts: (collectorId) => dispatch(getAllAlerts(collectorId)),
  handleCloseModal: (payload) => dispatch(handleModal(payload)),
  getCollectorProcess: (username) => dispatch(getCollectorProcess(username))
  // authMe : () => dispatch(authMe())
});

export default connect(mapStateToProps, mapDispatchToProps)(AltHeader);
