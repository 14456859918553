import { handleClickInfo, handleError } from "../actions/collectorAction"
import { collectorConstants } from "../constants/ActionTypes"
import axios from "axios";

const { REACT_APP_JAVA_API_URL } = process.env;

const initialState = {
    collectors: [],
    attendance: [],
    deleteCollector: [],
    allocatedCounts: [],
    collectorProcess: {}
}
const collektoUser = JSON.parse(localStorage.getItem("collektoUser"));

const deactivateAlert = async (id) => {
    try {
        const axiosReq = axios.create({
            headers: {
                collectorId: collektoUser?._id
            }
        })
        axiosReq.put(`${REACT_APP_JAVA_API_URL}/api/v2/alerts/${id}/deactivate`);
    }
    catch (e) {
        handleError(e.message);
    }
}

export default (state = initialState, { type, payload }) => {
    switch (type) {

        case collectorConstants.GET_COLLECTOR_ALL:
            return { ...state, collectors: payload }
        case collectorConstants.CLEAR_ALL_COLLECTORS:
            return { ...state, collectors: [] }
        case collectorConstants.GET_COLLECTOR_PROCESS:
            return { ...state, collectorProcess: payload }

        case collectorConstants.GET_ATTENDANCE:
            return { ...state, attendance: payload }

        case collectorConstants.DELETE_COLLECTOR:
            return {
                ...state,
                collectors: state.collectors.filter(dv => (dv._id !== payload._id))
            }
        case collectorConstants.COLLECTOR_ALLOCATED_COUNT:
            return { ...state, allocatedCounts: payload }

        case collectorConstants.SET_MODAL:
            return { ...state, showAlertsModal: payload }
        case collectorConstants.GET_MISSED_ALERTS:
            return { ...state, missedAlerts: payload }
        case collectorConstants.GET_ALERTS_COUNT:
            return { ...state, alertsCount: payload }
        case collectorConstants.SHOWALERTSMODAL:
            return { ...state, showAlertsModal: payload }
        case collectorConstants.GET_ALERTS_ALL:
            {
                payload.forEach(el => {
                    const { alertType, customerName, alertTime, loanNumber } = el;
                    let text;
                    if (alertType === "FieldDisposition") {
                        text = `Disposition updated for ${loanNumber}`;
                    }
                    handleClickInfo((text || `Alert for ${customerName} for ${alertType} on ${alertTime}`), el, deactivateAlert);
                })
                return { ...state, alerts: payload }
            }

        case collectorConstants.GET_NOTIFICATION_COUNT:
            return { ...state, notificationCount: payload?.count }

        default:
            return state
    }
}
